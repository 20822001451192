.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.buttonsContainer {
  flex-shrink: 0;
  margin-top: auto;
}
.numpadContainer {
  display: grid;
  grid-template-columns: 105px 105px 106px;
  margin-top: 16px;
}
.numpadButton {
  height: 64px;
  border: 1px solid black;
  border-right: 0;
  border-bottom: 0;
  padding: 0;
  font-size: 32px;
  display: flex;
  align-items: center; /* Needed for Firefox */
  justify-content: center;
  user-select: none;
  touch-action: manipulation; /* Disables double-tap zoom on touch devices */
}
.numpadButton:active:not(:disabled) {
  outline: 0;
  background-color: #1cb265;
  color: #fff;
}
.numpadButton:active.notAllowed {
  background-color: #e84855;
}
.numpadButtonTopLeft {
  border-top-left-radius: 4px;
}
.numpadButtonTopRight {
  border-top-right-radius: 4px;
}
.numpadButtonBottomLeft {
  border-bottom-left-radius: 4px;
}
.numpadButtonBottomRight {
  border-bottom-right-radius: 4px;
}
.numpadButtonRight {
  border-right: 1px solid black;
}
.numpadButtonBottom {
  border-bottom: 1px solid black;
}
.addRecordButton {
  margin: 16px 0 40px;
  width: 100%;
  border: 1px solid black;
}
.addRecordButton:disabled {
  font-size: 20px;
}
