header {
  background-color: #fff;
  border-bottom: 2px solid black;
  position: fixed;
  top: 0;
  height: 56px;
  width: 100%;
  z-index: 1;
}
.headerInnerContainer {
  display: flex;
  align-items: center;
  width: 316px;
  height: 100%;
  font-size: 20px;
  margin: 0 auto;
  position: relative;
}
.headerBeepy {
  margin-left: 16px;
}
.menuButton {
  margin-left: auto;
  margin-right: -16px;
  border: 0;
  padding: 8px 16px;
  display: flex;
}
nav {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  height: calc(100vh - 56px);
}
ul {
  padding-top: 16px;
}
.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border: 0;
  text-align: left;
  font-size: inherit;
  color: #666;
}
.menuItem > svg {
  margin-right: 16px;
  fill: #666;
}
.menuItemCurrent {
  font-weight: 500;
  color: #000;
}
.menuItemCurrent > svg {
  fill: #000;
}
.loginInfo {
  margin-top: 16px;
  padding-top: 32px;
}
.logoutButton {
  font-size: 16px;
  padding: 8px 16px;
  margin-top: 16px;
  border: 1px solid black;
}
.logoutButton:active {
  outline: 0;
  background-color: #1cb265;
  color: #fff;
}
