main {
  height: calc(100vh - 80px);
  width: 316px;
  margin: 80px auto 0;
}
.mainHidden {
  display: none;
}
.initialScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
