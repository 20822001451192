.container {
  display: flex;
  flex-direction: column;
  font-family: "Roboto Mono", monospace;
  min-height: 0;
}
.header {
  flex-shrink: 0;
  display: flex;
  height: 36px;
  border-bottom: 1px solid black;
}
.cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  width: 60px;
}
.header .cell {
  font-size: 16px;
  justify-content: flex-end;
}
.header .cell.timeCell {
  font-size: 16px;
  justify-content: flex-start;
}
.timeCell {
  justify-content: flex-start;
  font-size: 14px;
  width: 116px;
}
.pulseCell {
  width: 80px;
  padding: 0 16px 0 0px;
}
.body {
  min-height: 72px;
  overflow-y: auto;
}
.row {
  height: 36px;
  font-size: 20px;
  font-family: inherit;
  border: 0;
}
.row:not(.newRecord) {
  cursor: pointer;
}
.row.rowEditing {
  background-color: #ddd;
}
/*
  Without this @media, mobile Safari applies the hover state 
  when the row is tapped and then tapped again.
*/
@media (hover: hover) {
  .row:hover:not(.rowEditing):not(.newRecord) {
    background-color: #eee;
  }
}
.rowInner {
  display: flex;
  height: 100%;
}
.newRecord {
  background-color: #ddd;
}
.withCursor::after {
  content: "|";
  position: relative;
  top: -1px;
}
.editContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  background-color: #eee;
}
.dangerButton {
  border: 1px solid #c11826;
  font-size: 16px;
  height: 36px;
  color: #c11826;
}
.dangerButton:active {
  outline: 0;
  background-color: #e84855;
  color: #fff;
}
.ghostButton {
  border: 0;
  font-size: 16px;
  height: 36px;
}
.ghostButton:active {
  outline: 0;
  background-color: #ccc;
}
.deleteRecordButton {
  width: 164px;
}
.deleteConfirmButton {
  width: 80px;
}
.deleteCancelButton {
  width: 76px;
  margin-left: 8px;
}
