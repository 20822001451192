body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input {
  font-family: "Roboto", sans-serif;
  border: 1px solid black;
  border-radius: 0; /* For iOS */
  appearance: none;
}
input:focus {
  border-color: transparent;
  outline: 4px solid #1cb265;
}
button {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background: none;
  -webkit-tap-highlight-color: transparent;
}
button:disabled {
  border: 0;
  color: #777;
}
button:active {
  color: inherit; /* Otherwise, it's white in Safari. */
}
/*
  https://github.com/WICG/focus-visible#backwards-compatibility
  Provide basic, default focus styles.
*/
:focus {
  outline: 4px solid #1cb265;
}
/*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
*/
:focus:not(:focus-visible) {
  outline: 0;
}
/*
  If :focus-visible is supported on this platform, 
  provide enhanced focus styles for keyboard focus.
*/
:focus-visible {
  outline: 4px solid #1cb265;
}
* {
  box-sizing: border-box;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.errorMessage {
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  color: #e84855;
}
